import { Loader } from 'google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const loader = new Loader('my-api-key', {
  key: 'AIzaSyDtPqQNaqulrxB9rgp2DhTFIGzUC1UKZxs',
});

loader.load().then((google) => {
  const gmapSingle = () => {
    const mapId = $('#gmap-cluster');
    const map = new google.maps.Map(document.getElementById('gmap-single'), {
      mapTypeId: 'roadmap',
      scrollwheel: false,
      panControl: false,
      zoomControl: true,
      center: {
        lat: mapId.data('lat'),
        lng: mapId.data('lng'),
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoom: mapId.data('zoom'),
    });

    const markerSingle = () => new google.maps.Marker({
      position: {
        lat: mapId.data('lat'),
        lng: mapId.data('lng'),
      },
      map,
      title: 'Hello World!',
      icon: 'theme/styles/assets/icons/map-marker.png',
    });

    markerSingle();
  };

  const gmapMultiple = () => {
    const gmapMuliple = document.getElementById('gmap-multiple');
    const map = new google.maps.Map(gmapMuliple, {
      zoom: $('#gmap-multiple').data('zoom'),
      center: {
        lat: $('#gmap-multiple').data('lat'),
        lng: $('#gmap-multiple').data('lng'),
      },
    });

    const infoWin = new google.maps.InfoWindow();

    window.locationsMultiple.forEach((location) => {
      const { link, label, title } = location;

      const marker = new google.maps.Marker({
        position: location,
        map,
        icon: 'theme/styles/assets/icons/map-marker.png',
      });

      google.maps.event.addListener(marker, 'click', () => {
        infoWin.setContent(`<div class="pa-5"><h2 class="title-4">${title}</h2><div class="mt-10"><a href=${link} class="button-regular">${label}</a></div></div>`);
        infoWin.open(map, marker);
      });
      return marker;
    });
  };

  const gmapCluster = () => {
    const mapId = $('#gmap-cluster');
    const map = new google.maps.Map(document.getElementById('gmap-cluster'), {
      zoom: mapId.data('zoom'),
      center: {
        lat: mapId.data('lat'),
        lng: mapId.data('lng'),
      },
    });

    const infoWin = new google.maps.InfoWindow();
    const markers = window.locationsCluster.map((location) => {
      const { link, label, title } = location;

      const marker = new google.maps.Marker({
        position: location,
        icon: 'theme/styles/assets/icons/map-marker.png',
      });

      google.maps.event.addListener(marker, 'click', () => {
        infoWin.setContent(`<div class="pa-5"><h2 class="title-4">${title}</h2><div class="mt-10"><a href=${link} class="button-regular">${label}</a></div></div>`);
        infoWin.open(map, marker);
      });
      return marker;
    });

    const markerCluster = () => new MarkerClusterer({ markers, map });
    markerCluster();
  };

  if ($('#gmap-single').length) {
    gmapSingle();
  }

  if ($('#gmap-multiple').length) {
    gmapMultiple();
  }

  if ($('#gmap-cluster').length) {
    gmapCluster();
  }
});
